import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { hydrate, render } from "react-dom";
import "./index.css";

import { ThemeProvider } from "./contexts/ThemeContext";
import { PageProvider } from "./contexts/PageContext";
import { ApiProvider } from "./contexts/ApiContext";
import App from "./App";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate((
    <Router>
      <ThemeProvider>
        <PageProvider>
          <ApiProvider>
            <AnimatePresence mode="wait" >
              <App />
            </AnimatePresence>
          </ApiProvider>
        </PageProvider>
      </ThemeProvider>
    </Router>
  ), rootElement);
}
else {
  render((
    <Router>
      <ThemeProvider>
        <PageProvider>
          <ApiProvider>
            <AnimatePresence mode="wait" >
              <App />
            </AnimatePresence>
          </ApiProvider>
        </PageProvider>
      </ThemeProvider>
    </Router>
  ), rootElement);
}