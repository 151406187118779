import React, { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";

import Alert from "../components/Alert";
import PageContainer from "../components/PageContainer";

import countries from "../extensions/countries";
import { usePage } from "../contexts/PageContext";
import { useApi } from "../contexts/ApiContext";

const Contact = () => {
  const { setCurrentPage } = usePage();
  const { apiFetch } = useApi();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "contact",
        hideNavbar: false,
        hideFooter: false,
        meta: {
          title: "Contact | Isaiah Wiesner",
          description: "Isaiah Wiesner - Contact me today!",
          canonical: "https://isaiahwiesner.com/contact",
          meta: {
            charset: "utf-8",
            name: {
              title: "Contact | Isaiah Wiesner",
              keywords: "isaiah,wiesner,contact,web,development,website,api",
              "og:description": "Isaiah Wiesner - Contact me today!"
            }
          }
        }
      });
    };
    unsub();
  }, []);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const callingCodeRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setLoading(true);
    const body = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      callingCode: callingCodeRef.current.value,
      message: messageRef.current.value
    }
    const res = await apiFetch("/contact", "POST", body);
    if (res.ok) {
      firstNameRef.current.value = "";
      lastNameRef.current.value = "";
      emailRef.current.value = "";
      phoneRef.current.value = "";
      messageRef.current.value = "";
      setMessage("Message successfully sent!");
      setLoading(false);
    }
    else {
      const data = await res.json();
      setError({ ...data });
      setLoading(false);
    }
  };

  return (
    <PageContainer className="!pt-24">
      <div className="card w-full max-w-screen-sm mx-auto">
        <div className="card-body flex flex-col gap-4">
          <h2>Contact</h2>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className={`form-group required ${(error && ((error.errors && error.errors.firstName) || error.all)) ? "error" : ""}`}>
              <label htmlFor="firstName">First Name</label>
              <input
                className="form-control"
                type="text"
                id="firstName"
                ref={firstNameRef}
                autoComplete="off" disabled={loading}
              />
            </div>
            <div className={`form-group required ${(error && ((error.errors && error.errors.lastName) || error.all)) ? "error" : ""}`}>
              <label htmlFor="lastName">Last Name</label>
              <input
                className="form-control"
                type="text"
                id="lastName"
                ref={lastNameRef}
                autoComplete="off" disabled={loading}
              />
            </div>
            <div className={`form-group required ${(error && ((error.errors && error.errors.email) || error.all)) ? "error" : ""}`}>
              <label htmlFor="email">Email Address</label>
              <input
                className="form-control"
                type="text"
                id="email"
                ref={emailRef}
                autoComplete="off" disabled={loading}
              />
            </div>
            <div className={`form-group recommended ${(error && ((error.errors && error.errors.phone) || error.all)) ? "error" : ""}`}>
              <label htmlFor="phone">Phone Number</label>
              <div className="input-group">
                <select ref={callingCodeRef} className="form-control" disabled={loading}>
                  {[...countries.getContinents(true)].map(continent => {
                    return (
                      <optgroup label={continent} key={continent}>
                        {[...countries.getCountriesByContinent(continent, "callingCode")
                          .filter(c => c.callingCode !== null)].map(c => {
                            return (
                              <option value={c.callingCode} key={c.country}>+{c.callingCode} ({c.abbrev ? c.abbrev : c.country})</option>
                            );
                          })}
                      </optgroup>
                    );
                  })}
                </select>
                <input
                  className="form-control"
                  type="text"
                  id="phone"
                  ref={phoneRef}
                  autoComplete="off"
                  disabled={loading}
                />
              </div>
            </div>
            <div className={`form-group required md:col-span-2 ${(error && ((error.errors && error.errors.message) || error.all)) ? "error" : ""}`}>
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                rows="3"
                className="form-control"
                ref={messageRef}
                autoComplete="off"
                disabled={loading}
              ></textarea>
            </div>
            <Alert variant="success" className="md:col-span-2" hidden={!message} onHide={() => setMessage(null)}>
              {message && (
                <p>{message}</p>
              )}
            </Alert>
            <Alert variant="danger" className="md:col-span-2" hidden={!error} onHide={() => setError(null)}>
              {error && (
                <>
                  {(typeof error === "string") ? (
                    <p>{error}</p>
                  ) : (
                    <div className="flex flex-col">
                      <p>{error.detail || "Unable to send message."}</p>
                      {error.errors && (
                        <ul className="list-disc">
                          {Object.keys(error.errors).map(e => {
                            return (
                              <li key={e} className="ml-5">{error.errors[e]}</li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  )}
                </>
              )}
            </Alert>
            <button type="submit" className="btn btn-main md:col-span-2" disabled={loading}>
              Send Message
            </button>
          </form>
        </div>
      </div>
    </PageContainer>
  );
};

export default Contact;