import React, { useContext, useEffect, useState } from "react";
import generalizePath from "../functions/generalizePath";

const ApiContext = React.createContext();

export const useApi = () => {
  return useContext(ApiContext);
};

export const ApiProvider = ({ children }) => {
  const [ip, setIp] = useState(null);
  
  const updateIp = async () => {
    const res = await fetch("https://api.ipify.org/?format=json");
    if (res.ok) {
      const data = await res.json();
      setIp(data.ip);
    }
  };

  useEffect(() => {
    updateIp();
  }, []);

  const apiFetch = (path, method = "GET", body = null) => {
    return new Promise(async (resolve) => {
      const reqData = { method, headers: {}, body: null };
      if (ip) reqData.headers["X-Forwarded-For"] = ip;
      if (body) {
        reqData.headers["Content-Type"] = "application/json";
        reqData.body = JSON.stringify(body);
      }
      const res = await fetch(`/api/${generalizePath(path)}`, reqData);
      resolve(res);
    });
  };

  const value = {
    ip,
    apiFetch
  };

  return (
    <ApiContext.Provider value={value}>
      {children && children}
    </ApiContext.Provider>
  );
};