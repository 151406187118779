import React, { useContext, useState } from "react";
import DocumentMeta from "react-document-meta";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PageContext = React.createContext();

export const usePage = () => {
  return useContext(PageContext);
};

export const PageProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState({
    id: "home",
    hideNavbar: false,
    hideFooter: false,
    meta: {
      title: "Isaiah Wiesner",
      description: "Isaiah Wiesner - Custom Crafted Web Solutions",
      canonical: "https://isaiahwiesner.com",
      meta: {
        charset: "utf-8",
        name: {
          title: "Isaiah Wiesner",
          keywords: "isaiah,wiesner,web,development,website,api",
          "og:description": "Isaiah Wiesner - Custom Crafted Web Solutions"
        }
      }
    }
  });

  const value = {
    currentPage, setCurrentPage
  };

  return (
    <PageContext.Provider value={value}>
      <DocumentMeta {...currentPage.meta}>
        <div className="relative">
          {!currentPage.hideNavbar && (
            <Navbar active={currentPage.id} />
          )}
          {children && children}
          {!currentPage.hideFooter && (
            <Footer />
          )}
        </div>
      </DocumentMeta>
    </PageContext.Provider>
  );
};