import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";

const App = () => {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>

      <Route exact path="/" element={<Homepage />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/*" element={<NotFound />} />

    </Routes>
  );
};

export default App;
