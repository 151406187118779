import React, { useEffect } from "react";
import PageContainer from "../components/PageContainer";
import { usePage } from "../contexts/PageContext";
import { Link } from "react-router-dom";

const NotFound = () => {
  const { setCurrentPage } = usePage();
  useEffect(() => {
    setCurrentPage({
      id: "notFound",
      hideNavbar: false,
      hideFooter: false,
      meta: {
        title: "Page Not Found | Isaiah Wiesner",
        description: "Isaiah Wiesner - Page Not Found",
        canonical: "https://isaiahwiesner.com/",
        meta: {
          charset: "utf-8",
          name: {
            title: "Page Not Found | Isaiah Wiesner",
            keywords: "isaiah,wiesner,404,not,found,web,development,website,api",
            "og:description": "Isaiah Wiesner - Page Not Found"
          }
        }
      }
    })
  }, []);
  return (
    <PageContainer className="max-w-screen-md !pt-24 flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <h1 className="text-5xl">404</h1>
        <h2 className="text-main-700 dark:text-main-200">Page Not Found</h2>
      </div>
      <div className="flex flex-col gap-4">
        <p>The page you are looking for does not exist. Please check the URL and try again.</p>
        <Link to="/" className="btn btn-outline-main w-fit px-8" role="button">
          Back to Homepage
        </Link>
      </div>
    </PageContainer>
  );
};

export default NotFound;