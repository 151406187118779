import React from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const handleUnfocus = () => {
    document.activeElement.blur();
  };
  return (
    <footer>
      <p>Copyright &copy; {new Date().getFullYear()} Isaiah Wiesner</p>
      <div className="footer-btns">
        <a href="mailto:isaiah@isaiahwiesner.com" className="footer-btn" onClick={handleUnfocus}>
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
        <a href="https://github.com/isaiahwiesner" target="_blank" className="footer-btn" onClick={handleUnfocus}>
          <FontAwesomeIcon icon={faGithub} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;