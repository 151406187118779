import React from "react";
import { motion } from "framer-motion";

const PageContainer = ({ children, className, ...props }) => {
  return (
    <motion.div
      exit={{ opacity: 0, translateY: "-50px" }}
      initial={{ opacity: 0, translateY: "-50px" }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.3 }}
      className={`container ${className?className:""}`}
      {...props}
    >
      {children && children}
    </motion.div>
  );
};

export default PageContainer;