import React, { useContext, useEffect, useState } from "react";

const ThemeContext = React.createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const defaultDarkMode = () => {
    return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
  };

  const [darkMode, setDarkMode] = useState(defaultDarkMode());
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    if (darkMode) {
      document.body.parentElement.classList.add("dark");
    }
    else {
      document.body.parentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const value = {
    darkMode, setDarkMode,
    toggleDarkMode
  };

  return (
    <ThemeContext.Provider value={value}>
      {children && children}
    </ThemeContext.Provider>
  )
};