import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMoon, faSun, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../contexts/ThemeContext";
import logo from "../assets/Logo.svg"

const Navbar = ({ active = null }) => {
  const { toggleDarkMode } = useTheme();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleUnfocus = () => {
    document.activeElement.blur();
  };
  const handleToggleDarkMode = () => {
    toggleDarkMode();
    handleUnfocus();
  };
  const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };
  const handleCloseSidebar = () => {
    setSidebarOpen(false);
    handleUnfocus();
  };

  return (
    <nav>
      <div className="nav-container">
        <Link to="/" className="logo" onClick={handleUnfocus}>
          <div className="flex gap-8 items-center">
            <img src={logo} alt="Isaiah Wiesner" className="w-auto h-8" />
            <h3 className="hidden md:block">Isaiah Wiesner</h3>
          </div>
        </Link>
        <div className="menubtn-container">
          <button type="button" className="menubtn" onClick={handleOpenSidebar}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div className={`sidebar-bg ${sidebarOpen ? "open" : ""}`} />
        <div className={`nav-items ${sidebarOpen ? "open" : ""}`}>
          <div className="mobile-header">
            <button type="button" className="menubtn" onClick={handleCloseSidebar}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
            <div className="theme-switch-container">
              <button className="theme-switch" onClick={handleToggleDarkMode}>
                <div className="theme-switch-btn">
                  <FontAwesomeIcon icon={faMoon} className="theme-switch-icon dark-icon" />
                  <FontAwesomeIcon icon={faSun} className="theme-switch-icon light-icon" />
                </div>
              </button>
            </div>
          </div>
          <Link to="/" className={`nav-item md:hidden ${active === "home" ? "active" : ""}`} onClick={handleCloseSidebar}>Home</Link>
          <Link to="/about" className={`nav-item ${active === "about" ? "active" : ""}`} onClick={handleCloseSidebar}>About</Link>
          <Link to="/contact" className={`nav-item ${active === "contact" ? "active" : ""}`} onClick={handleCloseSidebar}>Contact</Link>
          <div className="theme-switch-container">
            <button className="theme-switch" onClick={handleToggleDarkMode}>
              <div className="theme-switch-btn">
                <FontAwesomeIcon icon={faMoon} className="theme-switch-icon dark-icon" />
                <FontAwesomeIcon icon={faSun} className="theme-switch-icon light-icon" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;