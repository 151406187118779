import React, { useEffect } from "react";
import { motion, useScroll } from "framer-motion";

import PageContainer from "../components/PageContainer";

import { usePage } from "../contexts/PageContext";

const About = () => {
  const { setCurrentPage } = usePage();
  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "about",
        hideNavbar: false,
        hideFooter: false,
        meta: {
          title: "About | Isaiah Wiesner",
          description: "Isaiah Wiesner - About Isaiah Wiesner",
          canonical: "https://isaiahwiesner.com/about",
          meta: {
            charset: "utf-8",
            name: {
              title: "About | Isaiah Wiesner",
              keywords: "isaiah,wiesner,about,web,development,website,api",
              "og:description": "Isaiah Wiesner - About Isaiah Wiesner"
            }
          }
        }
      });
    };
    unsub();
  }, []);

  const { scrollYProgress } = useScroll();

  return (
    <PageContainer className="max-w-screen-md flex flex-col gap-8">
      <motion.div
        className="fixed top-16 left-0 right-0 h-2 bg-main-500 dark:bg-main-600 origin-left"
        style={{ scaleX: scrollYProgress }}
      />
      <motion.div
        className="flex flex-col gap-4"
        initial={{ opacity: 0, translateX: "-50px" }}
        whileInView={{ opacity: 1, translateX: 0 }}
        transition={{ delay: 0.3, duration: 0.15 }}
      >
        <h1>About</h1>
        <p>Hi! My name is Isaiah Wiesner. I am a {Math.floor((Date.now() - new Date("2004-03-23").getTime()) / (1000 * 60 * 60 * 24 * 365))}-year-old aspiring web developer based in Ontario, Canada. Through years of dedicated practice and experience, I've honed my skills to craft effective web solutions for any challenge I encounter.</p>
      </motion.div>
      <motion.div
        className="flex flex-col gap-4"
        initial={{ opacity: 0, translateX: "-50px" }}
        whileInView={{ opacity: 1, translateX: 0 }}
        transition={{ delay: 0.3, duration: 0.15 }}
      >
        <motion.h2
          initial={{ opacity: 0, translateX: "-50px" }}
          whileInView={{ opacity: 1, translateX: 0 }}
          transition={{ delay: 0.3, duration: 0.15 }}
        >
          Introduction
        </motion.h2>
        <div className="flex flex-col gap-2">
          <motion.p
            initial={{ opacity: 0, translateX: "-50px" }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ delay: 0.3, duration: 0.15 }}
          >
            My passion for coding began at a young age, tracing back to when I first got my hands on a computer. Initially, my coding journey started with the popular game Minecraft. I used command blocks to create various minigames and adventure games, which I played with my friends. This playful introduction to coding sparked my interest in software development.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, translateX: "-50px" }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ delay: 0.3, duration: 0.15 }}
          >
            As my curiosity and enthusiasm grew, I began hosting my own Minecraft servers. My initial involvement in creating games involved setting up simple command block-based games, but I soon transitioned to creating more complex and sophisticated plugins with advanced logic. This evolution not only enhanced the gameplay experience but also attracted more players to my servers.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, translateX: "-50px" }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ delay: 0.3, duration: 0.15 }}
          >
            With an increasing player count, I realized the need for a central platform to share updates and information about my servers. This led me to expand my skill set further by learning how to build websites. By creating user-friendly websites, I provided a space for my players to access server details, upcoming events, and other relevant content.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, translateX: "-50px" }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ delay: 0.3, duration: 0.15 }}
          >
            Through these experiences, my love for coding deepened, and I found joy in both the technical and creative aspects of software development. This foundation laid the groundwork for my ongoing journey in the world of coding and technology.
          </motion.p>
        </div>
      </motion.div>
      <div className="flex flex-col gap-4">
        <motion.h2
          initial={{ opacity: 0, translateX: "-50px" }}
          whileInView={{ opacity: 1, translateX: 0 }}
          transition={{ delay: 0.3, duration: 0.15 }}
        >
          Experiences
        </motion.h2>
        <div className="flex flex-col gap-2">
          <motion.p
            initial={{ opacity: 0, translateX: "-50px" }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ delay: 0.3, duration: 0.15 }}
          >
            My journey in web development spans a wide range of experiences and technologies. It began with the basics: learning HTML, CSS, and JavaScript to create simple web pages and interactive features. These foundational languages provided me with a solid understanding of web development and set the stage for further exploration.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, translateX: "-50px" }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ delay: 0.3, duration: 0.15 }}
          >
            As my skills grew, I explored more advanced frameworks and libraries. I gained experience in building numerous React applications, utilizing its component-based architecture to create dynamic and efficient user interfaces. React's state management and virtual DOM also allowed me to streamline the development process and build scalable applications.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, translateX: "-50px" }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ delay: 0.3, duration: 0.15 }}
          >
            In addition to front-end development, I've worked extensively on the back-end side. This includes creating various APIs using Express, FastAPI, and Flask. Express, a Node.js framework, enabled me to develop robust and flexible server-side applications. FastAPI offered high performance and easy integration with other technologies, while Flask provided a lightweight and straightforward approach to building web applications
          </motion.p>
          <motion.p
            initial={{ opacity: 0, translateX: "-50px" }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ delay: 0.3, duration: 0.15 }}
          >
            My exposure to a variety of frameworks and technologies has broadened my skill set and given me a holistic understanding of web development. I have gained hands-on experience in both front-end and back-end development, allowing me to create cohesive and well-rounded applications. This journey has not only expanded my technical abilities but also nurtured my passion for crafting user-centric and efficient web solutions.
          </motion.p>
        </div>
      </div>
    </PageContainer>
  );
};

export default About;