import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import PageContainer from "../components/PageContainer";

import { usePage } from "../contexts/PageContext";

const Homepage = () => {
  const { setCurrentPage } = usePage();
  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "home",
        hideNavbar: false,
        hideFooter: false,
        meta: {
          title: "Isaiah Wiesner",
          description: "Isaiah Wiesner - Custom Crafted Web Solutions",
          canonical: "https://isaiahwiesner.com",
          meta: {
            charset: "utf-8",
            name: {
              title: "Isaiah Wiesner",
              keywords: "isaiah,wiesner,web,development,website,api",
              "og:description": "Isaiah Wiesner - Custom Crafted Web Solutions"
            }
          }
        }
      });
    };
    unsub();
  }, []);

  return (
    <PageContainer className="!pt-32 max-w-screen-md flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <h1 className="font-normal">Hi, I'm</h1>
        <h1 className="text-5xl text-main-500">Isaiah Wiesner</h1>
        <h2 className="text-main-800 dark:text-main-200">I craft custom web solutions.</h2>
      </div>
      <div className="flex flex-col gap-2">
        <p>My passion for coding and web development came naturally to me at a young age, immersing myself in technology for over a decade through online resources and self trial and error. At {Math.floor((Date.now() - new Date("2004-03-23").getTime())/(1000*60*60*24*365))} years old today, I have honed my skills through years of dedicated practice and chose to pursue higher education, completing my first year of college this past spring. With a solid foundation in problem-solving and a passion for crafting innovative web solutions, I understand that every challenge requires a customized approach and creative resolution.</p>
      </div>
      <Link to="/contact" role="button" className="btn btn-outline-main w-fit px-8">
        Get in Touch
      </Link>
    </PageContainer>
  );
};

export default Homepage;